import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import Dropdown from "../dropdown";
import RemoveButton from "../removeButton";
import Input from "../input";
import DropdownActions from "../dropdownActions";
import { useValidationError } from "../../../helpers/hooks";
import styles from "./tags.css";

const Tags = ({ submitChanges, selectedTags, validation, showRequiredText }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const { t } = useTranslation();
  const refButton = useRef();
  const [mainErrors, setMainErrors] = useState({});
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isRequiredText = validation.required && !selectedTags?.length && showRequiredText;
  const [isEdited, setIsEdited] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    if (!selectedList.length) {
      setSelectedList(selectedTags);
    } else {
      const newList = selectedList.map((item, index) => {
        return selectedTags[index] ? selectedTags[index] : item;
      });
      setSelectedList(newList);
    }
  }, [selectedTags.length]);

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const onSubmit = list => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = list
        .map(item => {
          const { isNew, ...rest } = item;
          return { ...rest };
        })
        .filter(item => Object.values(item).some(listItem => listItem));

      submitChanges({ tags: updatedList }, "tags", "save_tag");
    }
  };

  const handleSubmit = (data, isNew, index) => {
    if (isNew) {
      const newList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data, isNew };
        }

        return { ...item, isNew };
      });

      setSelectedList(newList);
      onSubmit(newList);
    } else {
      const newList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data };
        }

        return { ...item };
      });

      setSelectedList(newList);
      onSubmit(newList);
    }
  };

  const handleAddEmpty = () => {
    if (refButton?.current && selectedList.filter(item => item.isNew).length) {
      refButton.current.click();
    }

    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = selectedList.filter(item => Object.values(item).some(listItem => listItem));

      if (selectedList.length === updatedList.length) {
        setSelectedList([
          ...selectedList,
          {
            tag: "",
            prkey: "",
            isNew: true
          }
        ]);
      }
    }
  };

  const handleRemove = index => {
    const newSelectedList = selectedList.filter((item, currentIndex) => {
      return String(index) !== String(currentIndex);
    });

    setSelectedList(newSelectedList);
    onSubmit(newSelectedList);
  };

  const selectedLength = selectedTags ? selectedTags.length : 0;
  const title = `${validation?.custom_name || `Skill${selectedLength > 1 ? "s" : ""}`}${selectedLength ? ` (${selectedLength})` : ""}`;

  return (
    <div>
      <Dropdown isRequired={isRequired} isRequiredText={isRequiredText} title={title} handleMouseMove={handleMouseMove}>
        <div>
          <div>
            {selectedList?.map((item, index) => {
              return (
                <TagsItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${item.tag}-job-skills-index-${index}`}
                  handleSubmit={handleSubmit}
                  handleRemove={handleRemove}
                  handleRemoveEmpty={handleRemove}
                  selected={item}
                  index={index}
                  refButton={refButton}
                  setIsEdited={setIsEdited}
                  isEdited={isEdited}
                  setMainErrors={setMainErrors}
                  mainErrors={mainErrors}
                  customName={validation?.custom_name.replace("ls", "l").replace("gs", "g")}
                />
              );
            })}
          </div>
        </div>
        <DropdownActions buttonHandle={handleAddEmpty} buttonText={t("dashboard_listings_edit_skill_addbutton")} />
      </Dropdown>
    </div>
  );
};

const TagsItem = ({
  selected,
  handleSubmit: onSubmit,
  index,
  isRequired,
  handleRemoveEmpty,
  handleRemove,
  refButton,
  isEdited,
  setIsEdited,
  setMainErrors,
  mainErrors
  // customName
}) => {
  const { t } = useTranslation();
  const containerRef = useRef();
  const { isNew } = selected;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const validate = ({ tag }) => {
    const errors = {};

    const requiredText = t("dashboard_common_required");

    if (!tag) {
      errors.tag = requiredText;
    }

    const mainErrorsValue = mainErrors;
    mainErrorsValue[index] = Object.keys(errors).length ? errors : null;
    setMainErrors(mainErrorsValue);

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      ...selected,
      isEdited: false
    },
    onSubmit: values => {
      const { tag, prkey } = values;
      return onSubmit({ tag, prkey }, isNew, index);
    },
    validate
  });

  const { values, errors, handleSubmit, setFieldValue, setFieldTouched } = formik;

  const handleChange = e => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }

    setFieldValue(e.target.name, e.target.value);
  };

  const onRemove = () => {
    if (selected.isNew) {
      handleRemoveEmpty(index);
      const mainErrorsValue = mainErrors;

      if (mainErrorsValue[index]) {
        mainErrorsValue[index] = null;
        setMainErrors(mainErrorsValue);
      }
    } else {
      handleRemove(index, index, true);
    }
  };

  const onHandleSubmit = () => {
    setFieldTouched("tag");
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (values.tag && values.isEdited && !Object.values(errors).some(item => item)) {
        handleSubmit();
        setFieldValue("isEdited", false);
      }
      setIsEdited(false);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (values.tag && values.isEdited && !Object.values(errors).some(item => item)) {
            handleSubmit();
            setFieldValue("isEdited", false);
          }
          setIsEdited(false);
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited, isEdited, values.tag, values.isEdited]);

  useValidationError(Object.values(mainErrors).some(item => Boolean(item)) ? { tags: "Error" } : "isRemove", "tags");

  return (
    <div
      ref={containerRef}
      className={styles.item}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
    >
      <Input
        error={errors.tag}
        isInvalid={errors.tag}
        name="tag"
        onChange={handleChange}
        required={isRequired}
        value={values.tag}
        placeholder={t("dashboard_listings_edit_skill_input_placeholder")}
        classNameWrap={styles.selectWrap}
        className={styles.input}
        errorClassName={styles.errorText}
      />

      <RemoveButton className={styles.removeButton} onClick={onRemove} />

      <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
    </div>
  );
};

export default Tags;
