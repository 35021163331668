import React, { useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import cn from "classnames";

import { ReactComponent as ArrowIcon } from "../../../media/icons/dropdown-arrow.svg";
import Dropdown from "../dropdown";
import RemoveButton from "../removeButton";
import DropdownActions from "../dropdownActions";
import { getPracticeById, detectDuplicateEntryPractices } from "../../../helpers";
import PracticesModal from "../practicesModal";
import styles from "./practices.module.css";
import { useValidationError } from "../../../helpers/hooks";

const Practices = ({ validation, selectedPractices = [], submitChanges, directoryPractices, showRequiredText, professions }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const refButton = useRef();

  const normalizeProfessions = useMemo(() => {
    if (professions.children) {
      return professions.children.map(item => {
        const parent = item.children.map(itemChildren => {
          const children = itemChildren.children.map(childChildItem => {
            return {
              parent_practice_id: itemChildren.practice_id,
              parent_parent_id: itemChildren.parent_id,
              parent_title: itemChildren.title,
              parent_parent_practice_id: item.practice_id,
              parent_parent_parent_id: item.parent_id,
              parent_parent_title: item.title,
              ...childChildItem
            };
          });

          return {
            parent_practice_id: item.practice_id,
            parent_parent_id: item.parent_id,
            parent_title: item.title,
            ...itemChildren,
            children
          };
        });

        return { ...item, children: parent };
      });
    }

    return [];
  }, [professions.children, professions.children?.length]);

  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isRequiredText = validation.required && !selectedPractices?.length && showRequiredText;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [mainErrors, setMainErrors] = useState({});
  const [isEdited, setIsEdited] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  const normalizePractices = { practice_id: "", key: "", isNew: true };

  useEffect(() => {
    if (!selectedList.length) {
      setSelectedList(selectedPractices);
    } else {
      const newList = selectedList.map((item, index) => {
        return selectedPractices[index] ? selectedPractices[index] : item;
      });
      setSelectedList(newList);
    }
  }, [selectedPractices.length]);

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const onSubmit = list => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = list
        .map(item => {
          const { isNew, ...rest } = item;
          return { ...rest };
        })
        .filter(item => Object.values(item).some(listItem => listItem));

      submitChanges({ practices: updatedList }, "practices", "save_practice");
    }
  };

  const handleSubmit = (data, isNew, index) => {
    if (isNew) {
      const newPractices = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data, isNew };
        }

        return { ...item, isNew };
      });

      setSelectedList(newPractices);
      onSubmit(newPractices);
    } else {
      const newPractices = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data };
        }

        return { ...item };
      });

      setSelectedList(newPractices);
      onSubmit(newPractices);
    }
  };

  const handleAddEmpty = () => {
    if (
      refButton?.current &&
      selectedList.filter(item => item.isNew).length &&
      !Object.values(mainErrors).filter(item => Boolean(item)).length
    ) {
      refButton.current.click();
    }

    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = selectedList.filter(item => Object.values(item).some(listItem => listItem));

      if (selectedList.length === updatedList.length) {
        setSelectedList([...selectedList, normalizePractices]);
      }
    }
  };

  const handleRemove = index => {
    const newSelectedList = selectedList.filter((item, currentIndex) => {
      return String(index) !== String(currentIndex);
    });

    setSelectedList(newSelectedList);
    onSubmit(newSelectedList);
  };

  const selectedLength = selectedPractices.length;
  const customName = validation?.custom_name?.replace("ies", "y");
  const title = `${validation?.custom_name || `Practice${selectedLength > 1 ? "s" : ""}`}${selectedLength ? ` (${selectedLength})` : ""}`;
  const buttonText = `Add ${customName}` || "Add";

  return (
    <div>
      <Dropdown isRequired={isRequired} title={title} isRequiredText={isRequiredText} handleMouseMove={handleMouseMove}>
        <div>
          <div>
            {selectedList.map((item, index) => {
              return (
                <PracticesItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${item.percentage}-practices-${item.radius}-index-${index}`}
                  handleSubmit={handleSubmit}
                  handleRemove={handleRemove}
                  handleRemoveEmpty={handleRemove}
                  selected={item}
                  refButton={refButton}
                  index={index}
                  isRequired={isRequired}
                  directoryPractices={directoryPractices}
                  selectedPractices={selectedPractices}
                  setIsEdited={setIsEdited}
                  isEdited={isEdited}
                  setMainErrors={setMainErrors}
                  mainErrors={mainErrors}
                  professions={{ children: normalizeProfessions }}
                />
              );
            })}
          </div>
        </div>
        <DropdownActions buttonHandle={handleAddEmpty} buttonText={buttonText} />
      </Dropdown>
    </div>
  );
};

const PracticesItem = ({
  selected,
  handleSubmit: onSubmit,
  index,
  directoryPractices,
  handleRemoveEmpty,
  handleRemove,
  selectedPractices,
  isEdited,
  setIsEdited,
  setMainErrors,
  mainErrors,
  refButton,
  professions
}) => {
  const { t } = useTranslation();
  const { isNew } = selected;
  const [activePractices, setActivePractices] = useState(false);
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const containerRef = useRef(null);
  const [isTouched, setIsTouched] = useState(false);

  const validate = ({ practice_id }) => {
    const errors = {};
    const requiredText = t("dashboard_common_required");
    const duplicateText = t("dashboard_single_listing_popup_practice_error_duplicate");

    if (!practice_id) {
      errors.practice_id = requiredText;
    }

    if (practice_id && detectDuplicateEntryPractices("practice_id", selectedPractices, values)) {
      errors.practice_id = duplicateText;
    }

    const mainErrorsValue = mainErrors;
    mainErrorsValue[index] = Object.keys(errors).length ? errors : null;
    setMainErrors(mainErrorsValue);

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...selected,
      percentage: selected.percentage ? parseInt(selected.percentage, 10) : "",
      radius: selected.radius ? parseInt(selected.radius, 10) : "",
      isEdited: false
    },
    onSubmit: values => {
      const { practice_id, percentage, experience, radius, prkey } = values;
      return onSubmit({ practice_id, percentage, experience, radius, prkey }, isNew, index);
    },
    validate
  });

  const { values, errors, handleSubmit, setFieldValue, setFieldTouched, setErrors } = formik;

  const handleSelectPractice = practiceId => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }

    setFieldValue("practice_id", practiceId);
    setActivePractices(false);
    setMainErrors({});

    if (!detectDuplicateEntryPractices("practice_id", selectedPractices, practiceId)) {
      setTimeout(() => {
        handleSubmit();
      }, 50);
    } else {
      setTimeout(() => {
        setMainErrors({ practice_id: t("dashboard_single_listing_popup_practice_error_duplicate") });
        setErrors({ practice_id: t("dashboard_single_listing_popup_practice_error_duplicate") });
      }, 50);
    }
  };

  const onRemove = () => {
    if (selected.isNew) {
      handleRemoveEmpty(index);
      const mainErrorsValue = mainErrors;

      if (mainErrorsValue[index]) {
        mainErrorsValue[index] = null;
        setMainErrors(mainErrorsValue);
      }
    } else {
      handleRemove(index, index, true);
    }
  };

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (values.practice_id && values.isEdited && !Object.values(errors).some(item => item)) {
            handleSubmit();
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited, values.practice_id, values.percentage, values.experience, values.radius]);

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (values.practice_id && values.isEdited && !Object.values(errors).some(item => item)) {
        handleSubmit();
      }
      setIsEdited(false);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const onHandleSubmit = () => {
    setFieldTouched("practice_id");
  };

  useValidationError(Object.values(mainErrors).some(item => Boolean(item)) ? { practice_id: "Error" } : "isRemove", "practice_id");

  return (
    <div ref={containerRef} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} onTouchStart={handleTouchStart}>
      <div className={styles.item}>
        <div className={cn(styles.practiceContent)}>
          <button
            type="button"
            className={cn(styles.practiceDropdown, errors.practice_id ? styles.practiceDropdownError : "", styles.practiceDropdownWidth)}
            onClick={() => setActivePractices(true)}
          >
            {getPracticeById(directoryPractices, values.practice_id, true)?.title || t("dashboard_listings_edit_industry_selecttext")}
            <ArrowIcon />
          </button>

          {errors.practice_id && <span className={styles.errorText}>{errors.practice_id}</span>}
        </div>

        <RemoveButton className={styles.removeButton} onClick={onRemove} />
      </div>
      {activePractices && (
        <PracticesModal handleSelectPractice={handleSelectPractice} closeModal={() => setActivePractices(false)} profession={professions} />
      )}
      <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
    </div>
  );
};

export default Practices;
