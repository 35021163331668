import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import cn from "classnames";

import Input from "../input";
import Dropdown from "../dropdown";
import RemoveButton from "../removeButton";
import Select from "../select";
import { detectDuplicateAllFields } from "../../../helpers";
import { getCountryNameFromCode, getStateNameFromCode, renderSelectCountries, renderSelectStates } from "../../../helpers/select";
import DropdownActions from "../dropdownActions";
import { phone as phoneRegex } from "../../../constants/regex";
import { customStyles as selectStyles } from "../../../constants/select";
import WorkingHours from "../workingHours";
import styles from "./locations.module.css";
import { useValidationError } from "../../../helpers/hooks";

const Locations = ({ states, countries, validation, selectedLocations, submitChanges, showRequiredText, isEvents }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const refButton = useRef();
  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isNotRequired = !Object.keys(validation).length;
  const isRequiredText = validation.required && !selectedLocations?.length && showRequiredText;
  const { t } = useTranslation();
  const [mainErrors, setMainErrors] = useState({});
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    if (!selectedList.length) {
      setSelectedList(selectedLocations);
    } else {
      const newList = selectedList.map((item, index) => {
        return selectedLocations[index] ? selectedLocations[index] : item;
      });
      setSelectedList(newList);
    }
  }, [selectedLocations.length]);

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const onSubmit = list => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = list
        .map(item => {
          const { isNew, ...rest } = item;
          return { ...rest };
        })
        .filter(item => Object.values(item).some(listItem => listItem));

      submitChanges({ lawfirms: updatedList }, "lawfirms", "save_location");
    }
  };

  const handleSubmit = (data, isNew, index) => {
    if (isNew) {
      const newSelectedList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data, isNew };
        }

        return { ...item, isNew };
      });

      setSelectedList(newSelectedList);
      onSubmit(newSelectedList);
    } else {
      const newSelectedList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data };
        }

        return { ...item };
      });

      setSelectedList(newSelectedList);
      onSubmit(newSelectedList);
    }
  };

  const handleAddEmpty = () => {
    if (refButton?.current && selectedList.filter(item => item.isNew).length) {
      refButton.current.click();
    }

    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = selectedList.filter(item => Object.values(item).some(listItem => listItem));

      if (selectedList.length === updatedList.length) {
        setSelectedList([...selectedList, { city: "", country: "", fax: "", state: "", phone: "", street: "", zip: "", isNew: true }]);
      }
    }
  };

  const handleRemove = index => {
    const newSelectedList = selectedList.filter((item, currentIndex) => {
      return String(index) !== String(currentIndex);
    });

    setSelectedList(newSelectedList);
    onSubmit(newSelectedList);
  };

  const selectedLength = selectedLocations.length;
  const title = `${validation?.custom_name || `Location${selectedLength > 1 ? "s" : ""}`}${selectedLength ? ` (${selectedLength})` : ""}`;

  return (
    <Dropdown isRequired={isRequired} title={title} isRequiredText={isRequiredText} handleMouseMove={handleMouseMove}>
      <div>
        <div>
          {selectedList.map((item, index) => {
            return (
              <LocationsItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.fax}-locations-${item.zip}-index-${index}`}
                handleSubmit={handleSubmit}
                handleRemove={handleRemove}
                handleRemoveEmpty={handleRemove}
                selected={item}
                index={index}
                states={states}
                countries={countries}
                isRequired={isRequired}
                isNotRequired={isNotRequired}
                selectedLocations={selectedLocations}
                refButton={refButton}
                setIsEdited={setIsEdited}
                isEdited={isEdited}
                setMainErrors={setMainErrors}
                mainErrors={mainErrors}
                isEvents={isEvents}
              />
            );
          })}
        </div>
      </div>
      <DropdownActions buttonHandle={handleAddEmpty} buttonText={t("dashboard_listings_edit_location_addbutton")} />
    </Dropdown>
  );
};

const LocationsItem = ({
  countries,
  states,
  selected,
  handleSubmit: onSubmit,
  index,
  handleRemove,
  handleRemoveEmpty,
  isRequired,
  isNotRequired,
  selectedLocations,
  refButton,
  isEdited,
  setIsEdited,
  setMainErrors,
  mainErrors,
  isEvents
}) => {
  const { t } = useTranslation();
  const { isNew } = selected;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const containerRef = useRef();

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const validate = values => {
    const { city, country, state, fax, phone, street, zip } = values;
    const errors = {};

    const duplicateText = "Such item has been added already";
    const requiredText = t("dashboard_common_required");
    const invalidPhoneText = "Please enter valid number";

    if (!city) {
      errors.city = requiredText;
    }

    if (street && detectDuplicateAllFields(selectedLocations, { city, country, state, fax, phone, street, zip }, index)) {
      errors.street = duplicateText;
    }
    if (!street) {
      errors.street = requiredText;
    }
    if (!country) {
      errors.country = requiredText;
    }
    if (!phone.match(phoneRegex)) {
      errors.phone = invalidPhoneText;
    }
    if (!phone) {
      errors.phone = requiredText;
    }
    if (!zip) {
      errors.zip = requiredText;
    }
    if (fax && !fax.match(phoneRegex)) {
      errors.fax = invalidPhoneText;
    }
    if (!state) {
      errors.state = requiredText;
    }

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...selected,
      country: { title: selected.country },
      state: { title: selected.state },
      isEdited: false
    },
    onSubmit: values => {
      const { city, country, fax, state, phone, street, zip, prkey, subs } = values;

      return onSubmit({ city, country: country.title, fax, state: state.title, phone, street, zip, subs: subs || {}, prkey }, isNew, index);
    },
    validate
  });

  const { values, errors, setFieldTouched, handleSubmit, setFieldValue } = formik;

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleSelectChange = (newValue, actionMeta) => {
    setFieldValue(actionMeta.name, newValue);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleSelectSubHours = newValue => {
    setFieldValue("subs", { hours: newValue });

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const onRemove = () => {
    if (selected.isNew) {
      handleRemoveEmpty(index);
      const mainErrorsValue = mainErrors;

      if (mainErrorsValue[index]) {
        mainErrorsValue[index] = null;
        setMainErrors(mainErrorsValue);
      }
    } else {
      handleRemove(index, index, true);
    }
  };

  const onHandleSubmit = () => {
    setFieldTouched("street");
    setFieldTouched("country");
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);
      if (
        (values.city || values.country || values.fax || values.state || values.phone || values.street || values.zip) &&
        values.isEdited &&
        !Object.values(errors).some(item => item)
      ) {
        handleSubmit();
      }
      setIsEdited(false);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (
            (values.city || values.country || values.fax || values.state || values.phone || values.street || values.zip) &&
            values.isEdited &&
            !Object.values(errors).some(item => item)
          ) {
            handleSubmit();
          }
          setIsEdited(false);
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited, values.city, values.country, values.fax, values.state, values.phone, values.street, values.zip]);

  // eslint-disable-next-line
  const countryOptions = renderSelectCountries(countries, false).map((item, index) => ({ id: index, title: item }));
  // eslint-disable-next-line
  const stateOptions = renderSelectStates(values.country.title, states, false).map((item, index) => ({ id: index, title: item }));

  useValidationError(Object.values(mainErrors).some(item => Boolean(item)) ? { locations: "Error" } : "isRemove", "locations");

  const hours = Array.isArray(selected?.subs?.hours) ? [] : selected?.subs?.hours;

  return (
    <div
      ref={containerRef}
      className={styles.locationItem}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
    >
      <div className={styles.itemContent}>
        <RemoveButton className={styles.removeButton} onClick={onRemove} />
        <span className={cn(styles.name, styles.nameMain)}>
          {index >= 1 ? (
            <>
              {t("dashboard_listings_edit_location_secondary")} {index + 1}
            </>
          ) : (
            <>{t("dashboard_listings_edit_location_primary")}</>
          )}
        </span>

        <Input
          classNameWrap={styles.inputWrap}
          className={styles.input}
          error={errors.street}
          id="street"
          isInvalid={errors.street}
          name="street"
          onBlur={e => handleBlur(e.target.name)}
          onChange={handleChange}
          required={isNotRequired}
          value={values.street}
          placeholder={t("dashboard_listings_edit_location_address_placeholder")}
        />

        <div className={styles.flex}>
          <span className={styles.label}>{t("dashboard_common_country")}</span>
          <Select
            error={errors.country}
            isInvalid={errors.country}
            getOptionLabel={option => getCountryNameFromCode(countries, option.title)}
            getOptionValue={option => option.title}
            inputId="country"
            name="country"
            options={countryOptions}
            onBlur={() => handleBlur("country")}
            handleChange={handleSelectChange}
            styles={selectStyles}
            required={isRequired}
            value={[values.country]}
            classNameWrap={styles.select}
            placeholder={t("inputcountry")}
          />
          <span className={styles.label}>{t("dashboard_common_state")}</span>
          <Select
            error={errors.state}
            getOptionLabel={option => getStateNameFromCode(states, option.title)}
            getOptionValue={option => option.title}
            id="state"
            inputId="state"
            isInvalid={errors.state}
            name="state"
            noOptionsMessage={() => "Please select your country first"}
            onBlur={() => handleBlur("state")}
            handleChange={handleSelectChange}
            options={states && stateOptions}
            required={isNotRequired}
            value={[values.state]}
            classNameWrap={styles.select}
            placeholder={t("inputstate")}
          />
        </div>

        <div className={styles.flex}>
          <span className={styles.label}>{t("dashboard_listings_edit_location_address_city")}</span>
          <Input
            error={errors.city}
            id="city"
            isInvalid={errors.city}
            name="city"
            onBlur={e => handleBlur(e.target.name)}
            onChange={handleChange}
            required={isNotRequired}
            value={values.city}
            className={styles.smallInput}
            classNameWrap={styles.smallWrapInput}
            placeholder={t("dashboard_listings_edit_location_address_city")}
          />
          <span className={styles.label}>{t("dashboard_listings_edit_location_address_zipcode")}</span>
          <Input
            error={errors.zip}
            id="zip"
            isInvalid={errors.zip}
            name="zip"
            onBlur={e => handleBlur(e.target.name)}
            onChange={handleChange}
            required={isNotRequired}
            value={values.zip}
            className={styles.smallInput}
            classNameWrap={styles.smallWrapInput}
            placeholder={t("dashboard_listings_edit_location_address_zipcode")}
          />
        </div>
        <div className={styles.flex}>
          <div className={styles.flexItem}>
            <span className={cn(styles.label, styles.nameMain)}>{t("dashboard_listings_edit_location_phone_placeholder")}</span>
            <Input
              error={errors.phone}
              id="phone"
              isInvalid={errors.phone}
              name="phone"
              onBlur={e => handleBlur(e.target.name)}
              onChange={handleChange}
              required={isNotRequired}
              value={values.phone}
              className={styles.smallInput}
              classNameWrap={styles.smallWrapInput}
              placeholder={t("dashboard_listings_edit_location_phone_placeholder")}
            />
          </div>
          <div className={styles.flexItem}>
            <span className={cn(styles.label, styles.nameMain)}>{t("dashboard_common_fax")}</span>
            <Input
              error={errors.fax}
              id="fax"
              isInvalid={errors.fax}
              name="fax"
              onBlur={e => handleBlur(e.target.name)}
              onChange={handleChange}
              value={values.fax}
              className={styles.smallInput}
              classNameWrap={styles.smallWrapInput}
              placeholder={t("dashboard_common_fax")}
            />
          </div>

          <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
        </div>
      </div>

      {!isEvents && <WorkingHours days={hours || []} handleSelectSubHours={handleSelectSubHours} />}
    </div>
  );
};

export default Locations;
